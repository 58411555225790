import React from 'react';
import styled, { keyframes } from 'styled-components';

import { blue, primary, text70 } from 'styles/colors';
import { mobile, useQuery } from 'styles/breakpoints';
import {
  FlexWrapper,
  H1,
  PrimaryButton,
  Regular,
  Svg,
  TextColor,
  TextWrapper,
} from 'components';
import { ComponentWithCtaEvent } from 'utils/interfaces';

interface SummaryHero extends ComponentWithCtaEvent {
  onCtaDataClick: () => void;
}

export const Hero: React.FC<SummaryHero> = ({ onCtaClick, onCtaDataClick }) => {
  const { isMobile } = useQuery();

  return (
    <Container alignItems="center" justifyContent="center">
      <FlexWrapper
        maxWidth="900px"
        width="100%"
        alignItems="center"
        flexDirection="column"
      >
        <H1 margin={isMobile ? '0 1.5rem 0 0' : null} textAlign="center">
          Here’s your condition overview
        </H1>
        <TextWrapper maxWidth="30.625rem" textAlign="center">
          <Regular
            color={text70}
            margin={isMobile ? '1.5rem 1.5rem 2.125rem 0' : '1.25rem 0 2.5rem'}
          >
            Based on your answers, we&apos;ve prepared your personal health
            summary to back up your cardiovascular system support plan. Reduce
            cholesterol, regulate blood pressure, manage sugar levels.
          </Regular>
        </TextWrapper>
        <PrimaryButton
          maxWidth={isMobile ? 'calc(100% - 1.5rem)' : '11.125rem'}
          onClick={onCtaClick}
        >
          GET MY PLAN
        </PrimaryButton>
        <ScrollDownButton
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ScrollDownText color={blue} margin="0" onClick={onCtaDataClick}>
            Scroll down for more
          </ScrollDownText>
          <ScrollDownArrow
            src="icons/arrow_down"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            onClick={onCtaDataClick}
          />
        </ScrollDownButton>
      </FlexWrapper>
    </Container>
  );
};

Hero.displayName = 'Hero';

const upAndDown = keyframes`
0%, 100% {
  transform: translateY(0);
}
50% {
  transform: translateY(-1.25rem);
}
`;

const Container = styled(FlexWrapper)`
  padding-top: 4.375rem;
  padding-bottom: 2.5rem;
  position='relative';
   @media ${mobile} {
    padding-left: 1.5rem;
    padding-top: 1.25rem;
  }
`;
const ScrollDownButton = styled(FlexWrapper)`
  margin-top: 3.125rem;
  @media ${mobile} {
    margin-top: 1.5rem;
  }
`;

const ScrollDownText = styled(Regular)`
  cursor: pointer;
  padding: 1rem;
`;

const ScrollDownArrow = styled(Svg)`
  margin-top: 0.9rem;
  cursor: pointer;
  animation: ${upAndDown} 1s linear infinite;
`;

// position: absolute;
// left: 0%;
// top: 14.5%;
// @media ${mobile} {
//   top: 10.6%;
// }
