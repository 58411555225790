import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Element, scroller } from 'react-scroll';

import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { images } from 'utils/images';
import { getSummaryData } from 'utils/summary';
import { mobile } from 'styles/breakpoints';
import { updateSummary } from 'state/user/effects';
import { Featured, Logo, PrivateRoute, Seo } from 'components';

import { Hero } from './sections/Hero';
import { SummaryData } from './sections/SummaryData';
import { OurPromise } from './sections/OurPromise';
import { Analytics } from 'apis/Analytics';

const Summary: React.FC = () => {
  const dispatch = useDispatch();
  const { goToCheckout } = useRouter();
  const { quiz_answers } = useSelector((s: AppState) => s.user);

  const handleCtaClick = () => {
    Analytics.trackCTAButton('summary');
    goToCheckout();
  };

  useEffect(() => {
    if (!quiz_answers) {
      return;
    }

    const {
      weight,
      height,
      isMetric,
      exercising,
      feel_full,
      meals_eat_most_often,
    } = quiz_answers;
    const data = getSummaryData({
      weight: parseFloat(weight as string) as number,
      height: parseFloat(height as string) as number,
      isMetric: isMetric as boolean,
      exercising: exercising as string,
      meals: meals_eat_most_often as string,
      feelingFullFrequency: feel_full as string,
    });

    dispatch(updateSummary(data));
  }, [dispatch, quiz_answers]);

  const handleCtaClickSummaryData = useCallback(() => {
    Analytics.trackCTAButton('summaryData');
    scroller.scrollTo('summaryData', {
      spy: true,
      smooth: true,
      duration: 500,
    });
  }, []);

  return (
    <>
      <Seo title="Summary | Pulsio" />
      <PrivateRoute>
        <Background>
          <Logo notClickable />
          <Hero
            onCtaClick={handleCtaClick}
            onCtaDataClick={handleCtaClickSummaryData}
          />
          <Element name="summaryData">
            <SummaryData onCtaClick={handleCtaClick} />
          </Element>
        </Background>
        <StyledFeatured ctaTitle="GET MY PLAN" onClick={handleCtaClick} />
        <OurPromise onCtaClick={handleCtaClick} />
      </PrivateRoute>
    </>
  );
};

export default Summary;

const Background = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 8rem;
  background-image: url(${images.summary_hero_background});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  @media ${mobile} {
    padding-bottom: 7.75rem;
  }
`;
const StyledFeatured = styled(Featured)`
  padding: 6.25rem 0 6.875rem;

  @media ${mobile} {
    padding: 4rem 1.5rem 5rem;
  }
`;
