import React from 'react';
import styled from 'styled-components';

interface Props {
  level: string;
}

const getWidthByLevel = (level: string) => {
  switch (level) {
    case 'low': {
      return 35;
    }
    case 'average': {
      return 55;
    }
    case 'high': {
      return 98;
    }
  }

  return 15;
};

const getEndColorByLevel = (level: string) => {
  switch (level) {
    case 'low': {
      return '#9C7D56';
    }
    case 'average': {
      return '#F2614F';
    }
    case 'high': {
      return '#F2614F';
    }
  }

  return '#688D5A';
};

export const GraphImage: React.FC<Props> = ({ level }) => (
  <Wrapper
    width={getWidthByLevel(level)}
    endColor={getEndColorByLevel(level)}
  />
);

const Wrapper = styled.div<{ width: number; endColor: string }>`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: rgba(242, 97, 79, 20%);
  border-radius: 8px;

  &::before {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: ${props => props.width}%;
    background: ${props =>
      `linear-gradient(to right, rgba(22, 166, 95) 0%, ${props.endColor} 100%)`};
  }

  &::after {
    content: '';
    position: absolute;
    top: -3px;
    height: calc(100% + 6px);
    left: ${props => props.width}%;
    background-color: ${props => props.endColor};
    width: 2px;
  }
`;
