import intersectionWith from 'lodash/intersectionWith';
import { QuizAnswer } from 'state/user/types';

import { convertCmToInch, convertKgtoLb } from './metrics';

const getMatchingMealsLength = (meals1: Array<string>, meals2: Array<string>) =>
  intersectionWith(
    meals1,
    meals2,
    (meal1: string, meal2: string) => meal1 === meal2,
  ).length;

export const calculateBmi = ({
  weight,
  height,
  isMetric,
}: {
  weight: number;
  height: number;
  isMetric: boolean;
}) => {
  let bmi: string | number = isMetric
    ? (weight / height / height) * 10000
    : (convertKgtoLb(weight) * 703) /
      (convertCmToInch(height) * convertCmToInch(height));

  bmi = bmi.toFixed(2);
  bmi = parseFloat(bmi);

  return bmi;
};

export const calculateFitnessLevel = (exercisingFrequency: string) => {
  if (exercisingFrequency === 'every_day') {
    return { value: 'excellent', label: 'Excellent' };
  } else if (exercisingFrequency === 'two_three_times_per_week') {
    return { value: 'above_average', label: 'Above average' };
  } else if (exercisingFrequency === 'once_a_week') {
    return { value: 'average', label: 'Average' };
  }

  return { value: 'poor', label: 'Poor' };
};

export const calculateDietResult = (
  feelingFullFrequency: string,
  meals: string,
) => {
  const mealsParsed = meals?.split('|');

  if (feelingFullFrequency !== 'almost_never') {
    const criticalMeals = [
      'pizza',
      'steak_dinner',
      'burger_and_fries',
      'mac_and_cheese',
    ];

    if (getMatchingMealsLength(mealsParsed, criticalMeals)) {
      return { value: 'critical', label: 'Critical' };
    }

    const poorMeals = [
      'salad',
      'oatmeal',
      'pancakes_with_syrup',
      'grilled_chicken',
    ];

    if (getMatchingMealsLength(mealsParsed, poorMeals)) {
      return { value: 'poor', label: 'Poor' };
    }
  }

  const poorMeals = [
    'pizza',
    'steak_dinner',
    'burger_and_fries',
    'mac_and_cheese',
  ];

  if (getMatchingMealsLength(mealsParsed, poorMeals)) {
    return { value: 'poor', label: 'Poor' };
  }

  return { value: 'average', label: 'Average' };
};

export const getSummaryData = ({
  weight,
  height,
  isMetric,
  exercising,
  feelingFullFrequency,
  meals,
}: {
  weight: number;
  height: number;
  isMetric: boolean;
  exercising: string;
  feelingFullFrequency: string;
  meals: string;
}) => ({
  fitnessLevel: calculateFitnessLevel(exercising),
  bmi: calculateBmi({ weight, height, isMetric }),
  diet: calculateDietResult(feelingFullFrequency, meals),
  weightUnit: isMetric ? 'kg' : 'lb',
  weight: isMetric ? weight : convertKgtoLb(weight),
  height,
});

export const getBmiGraphName = (bmi: number) => {
  if (bmi < 25) {
    return 'low';
  } else if (bmi < 30) {
    return 'average';
  }

  return 'high';
};

export const getAgeGraphName = (ageString: string) => {
  const ages = ageString?.split('-') || [];
  const [age] = ages;

  if (parseInt(age, 10) >= 55) {
    return 'high';
  }
  if (parseInt(age, 10) >= 40) {
    return 'high';
  }
  if (parseInt(age, 10) >= 30) {
    return 'average';
  }

  return 'low';
};

export const getFitnessLevelGraphName = (fitnessLevel: string) => {
  if (fitnessLevel === 'excellent') {
    return 'low';
  } else if (fitnessLevel === 'above_average') {
    return 'mid_lower';
  } else if (fitnessLevel === 'average') {
    return 'mid_high';
  }

  return 'high';
};

export const getDietGraphName = (fitnessLevel: string) => {
  if (fitnessLevel === 'average') {
    return 'mid_lower';
  } else if (fitnessLevel === 'poor') {
    return 'mid_high';
  }

  return 'high';
};

export const getCondition = (answers: QuizAnswer) => {
  let score = 0;
  const bmi = calculateBmi({
    weight: Number(answers.weight),
    height: Number(answers.height),
    isMetric: answers.isMetric as boolean,
  });

  switch (answers.age) {
    case '30-39': {
      score += 1;
      break;
    }
    case '40-55': {
      score += 2;
      break;
    }
    case '55+': {
      score += 3;
      break;
    }
    default:
      break;
  }

  if (bmi > 25) score += 1;
  if (bmi > 30) score += 2;

  switch (answers.work_out_recurrence) {
    case 'i_dont_exercise': {
      score += 1;
      break;
    }
    case 'once_a_week': {
      score += 1;
      break;
    }
    default:
      break;
  }
  const symptoms = answers.symptoms?.toString();
  const additional_symptoms = answers?.additional_symptoms?.toString();
  const conditions = answers.conditions?.toString();
  const operations_or_treatments = answers.operations_or_treatments?.toString();

  if (symptoms?.includes('dizziness')) score += 1;
  if (symptoms?.includes('fatigue')) score += 1;
  if (symptoms?.includes('finting')) score += 1;
  if (symptoms?.includes('nausea')) score += 1;
  if (symptoms?.includes('headaches')) score += 1;
  if (symptoms?.includes('irregular_heartbeats')) score += 1;
  if (symptoms?.includes('chest_pain')) score += 1;

  if (additional_symptoms?.includes('increased_thirst')) score += 1;
  if (additional_symptoms?.includes('frequent_urination')) score += 1;
  if (additional_symptoms?.includes('shortness_of_breath')) score += 1;
  if (additional_symptoms?.includes('stomach_pain')) score += 1;
  if (additional_symptoms?.includes('fruity_breath_odor')) score += 1;
  if (additional_symptoms?.includes('a_very_dry_mouth')) score += 1;

  if (conditions?.includes('metabolic_syndrome')) score += 1;
  if (conditions?.includes('chronic_kidney_disease')) score += 1;
  if (conditions?.includes('hiv_aids')) score += 1;
  if (conditions?.includes('crohns_disease')) score += 1;
  if (conditions?.includes('ulcerative_colitis')) score += 1;
  if (conditions?.includes('arthritis')) score += 1;
  if (conditions?.includes('other')) score += 1;

  if (operations_or_treatments?.includes('heart_operation')) score += 1;
  if (operations_or_treatments?.includes('organ_transplantation')) score += 1;
  if (operations_or_treatments?.includes('coronary_bypass_surgery')) score += 1;
  if (operations_or_treatments?.includes('heart_valve_repair')) score += 1;
  if (operations_or_treatments?.includes('aneurysm_repair')) score += 1;

  if (score <= 5) {
    return { yourResult: 8, withPulsio: 10 };
  }

  if (score <= 9) {
    return { yourResult: 5, withPulsio: 8 };
  }

  return { yourResult: 3, withPulsio: 7 };
};

export const getBloodSugarRisk = (answers: QuizAnswer) => {
  let score = 0;
  const bmi = calculateBmi({
    weight: Number(answers.weight),
    height: Number(answers.height),
    isMetric: answers.isMetric as boolean,
  });
  const additional_symptoms = answers?.additional_symptoms?.toString();

  switch (answers.age) {
    case '40-55': {
      score += 1;
      break;
    }
    case '55+': {
      score += 2;
      break;
    }
    default:
      break;
  }

  if (bmi > 25) score += 1;
  if (bmi > 30) score += 2;

  switch (answers.work_out_recurrence) {
    case 'i_dont_exercise':
    case 'once_a_week': {
      score += 1;
      break;
    }
    default:
      break;
  }

  if (additional_symptoms?.includes('increased_thirst')) score += 1;
  if (additional_symptoms?.includes('frequent_urination')) score += 1;
  if (additional_symptoms?.includes('shortness_of_breath')) score += 1;
  if (additional_symptoms?.includes('stomach_pain')) score += 1;
  if (additional_symptoms?.includes('fruity_breath_odor')) score += 1;
  if (additional_symptoms?.includes('a_very_dry_mouth')) score += 1;

  if (answers.diagnosed_diabetes === 'yes') score += 5;

  if (score <= 2) return 'Low';
  if (score <= 5) return 'Average';
  return 'High';
};

export const getBloodPressureRisk = (answers: QuizAnswer) => {
  let score = 0;

  const bmi = calculateBmi({
    weight: Number(answers.weight),
    height: Number(answers.height),
    isMetric: answers.isMetric as boolean,
  });

  switch (answers.age) {
    case '40-55': {
      score += 1;
      break;
    }
    case '55+': {
      score += 2;
      break;
    }
    default:
      break;
  }

  if (bmi > 25) score += 1;
  if (bmi > 30) score += 2;

  switch (answers.conditions) {
    case 'metabolic_syndrome':
    case 'chronic_kidney_disease':
    case 'hiv_aids':
    case 'crohns_disease':
    case 'ulcerative_colitis':
    case 'arthritis':
    case 'other': {
      score += 1;
      break;
    }
    default:
      break;
  }

  switch (answers.operations_or_treatments) {
    case 'heart_operation':
    case 'heart_valve_repair':
    case 'aneurysm_repair': {
      score += 1;
      break;
    }
    case 'coronary_bypass_surgery': {
      score += 2;
      break;
    }
    default:
      break;
  }

  switch (answers.stressed_or_anxious) {
    case 'very_often':
    case 'occasionally': {
      score += 1;
      break;
    }
    default:
      break;
  }

  switch (answers.recently_measured_blood_pressure) {
    case 'from_120_to_129_and_less_80': {
      score += 1;
      break;
    }
    case 'from_130_to_139_and_80_to_89': {
      score += 2;
      break;
    }
    case 'more_140_and_more_90': {
      score += 3;
      break;
    }
    default:
      break;
  }

  if (answers.diagnosed_high_blood_pressure === 'yes') score += 2;

  if (answers.family_with_heart_disease === 'yes') score += 2;

  if (score <= 4) {
    return 'Low';
  }

  if (score <= 7) {
    return 'Average';
  }

  return 'High';
};
