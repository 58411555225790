import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';

import { AppState } from 'state/types';
import { convertKgtoLb, getHeightImperial } from 'utils/metrics';
import { blue, blue50, primary, white } from 'styles/colors';
import {
  Caption,
  Circle,
  Container,
  FlexWrapper,
  GridWrapper,
  H2,
  H3,
  PrimaryButton,
  Small,
  Svg,
} from 'components';
import AlcoholGlassIcon from 'assets/images/quiz/alcohol_glass.svg';
import CigaretteIcon from 'assets/images/quiz/cigarette.svg';
import StressIcon from 'assets/images/quiz/stress.svg';
import {
  getAgeGraphName,
  getBloodPressureRisk,
  getBloodSugarRisk,
  getBmiGraphName,
  getCondition,
} from 'utils/summary';
import { mobile, useQuery, xsmDesktop } from 'styles/breakpoints';

import { Widget } from '../elements/Widget';
import { ComponentWithCtaEvent } from 'utils/interfaces';
import { GraphImage } from '../elements/GraphImage';
import { YourCondition } from '../elements/YourCondition';

export const SummaryData: React.FC<ComponentWithCtaEvent> = ({
  onCtaClick,
}) => {
  const { isXsmDesktop, isMobile } = useQuery();
  const { quiz_answers, summary } = useSelector((s: AppState) => s.user);

  if (!summary) {
    return null;
  }

  const { bmi, weightUnit } = summary;
  const {
    age,
    isMetric,
    height,
    weight,
    gender,
    stressed_or_anxious,
    regularly_consume_alcoholic_beverages,
    smoker,
  } = quiz_answers;

  const { yourResult, withPulsio } = useMemo(() => getCondition(quiz_answers), [
    quiz_answers,
  ]);
  const pressureRisk = useMemo(() => getBloodPressureRisk(quiz_answers), [
    quiz_answers,
  ]);
  const sugarRisk = useMemo(() => getBloodSugarRisk(quiz_answers), [
    quiz_answers,
  ]);

  const stressedOrAnxious = stressed_or_anxious !== 'doesnt_affect';
  const consumesAlcohol = smoker === 'yes';
  const usesTobaccoProducts = regularly_consume_alcoholic_beverages === 'yes';

  const [stressInfoVisible, setStressInfoVisible] = useState<boolean>(false);
  const [alcoholInfoVisible, setAlcoholInfoVisible] = useState<boolean>(false);
  const [tobaccoInfoVisible, setTobaccoInfoVisible] = useState<boolean>(false);

  return (
    <Container margin={isMobile ? '1.5rem 0 0 0' : ''}>
      <YourCondition yourResult={yourResult} withPulsio={withPulsio} />
      <GridWrapper
        columns={isXsmDesktop ? 1 : 3}
        gap={isXsmDesktop ? '0.5rem' : '1rem'}
        justifyItems={isXsmDesktop ? 'center' : null}
        padding={isXsmDesktop ? '0 0 0.5rem' : '0 0 1.875rem'}
      >
        <Widget height="10rem">
          <Svg
            src={`icons/gender_${gender}`}
            width="43"
            height="43"
            viewBox="0 0 60 60"
            fill="none"
          />
          <Caption color={blue} padding="1.125rem 0 0">
            {capitalize(gender as string).replace('_', '-')}
          </Caption>
        </Widget>
        <Widget height="10rem">
          <H2 color={blue} margin="-0.5rem auto 0">
            {isMetric ? height : getHeightImperial(height)}
          </H2>
          <Caption color={blue} padding="0.75rem 0 0">
            Height ({isMetric ? 'cm' : 'ft'})
          </Caption>
        </Widget>
        <Widget height="10rem">
          <H2 color={blue} margin="-0.5rem auto 0">
            {isMetric ? weight : convertKgtoLb(weight as number)}
          </H2>
          <Caption color={blue} padding="0.75rem 0 0">
            Weight ({weightUnit})
          </Caption>
        </Widget>
      </GridWrapper>
      <GridWrapper
        columns={isXsmDesktop ? 1 : 2}
        gap={isXsmDesktop ? '0.5rem' : '1rem'}
        justifyItems={isXsmDesktop ? 'center' : null}
        padding={isXsmDesktop ? '0 0 0.5rem' : '0 0 1.875rem'}
      >
        <WidgetWithGraph
          maxWidth="38.125rem"
          height={isXsmDesktop ? '23.125rem' : '20rem'}
        >
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <Caption color={blue}>BMI</Caption>
            <H2 color={blue}>{parseInt(bmi.toString(), 10)}</H2>
          </FlexWrapper>
          <GraphImage level={getBmiGraphName(bmi)} />
          <Small color={blue50}>
            Your BMI is an indicator of total body fat and is used to screen for
            weight categories that may lead to heart problems. Obese individuals
            require more blood to supply vital body functions, resulting in
            increased blood pressure. This, in turn, can cause heart-related
            complications.
          </Small>
        </WidgetWithGraph>
        <WidgetWithGraph
          maxWidth="38.125rem"
          height={isXsmDesktop ? '18.5rem' : '20rem'}
        >
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <Caption color={blue}>Age</Caption>
            <H2 color={blue}>{age}</H2>
          </FlexWrapper>

          <GraphImage level={getAgeGraphName(age as string)} />
          <Small color={blue50} padding="1.375rem 0 0">
            Age causes changes in heart and blood vessels. When you get older,
            your heart can’t beat as fast as needed during exercise or in times
            of stress. Also, the blood vessels become less flexible and more
            prone to collecting fat deposits.
          </Small>
        </WidgetWithGraph>
      </GridWrapper>
      <GridWrapper
        columns={isXsmDesktop ? 1 : 2}
        gap={isXsmDesktop ? '0.5rem' : '1rem'}
        justifyItems={isXsmDesktop ? 'center' : null}
        padding={isXsmDesktop ? '0 0 0.5rem' : '0 0 1.875rem'}
      >
        <WidgetWithGraph
          maxWidth="38.125rem"
          height={isXsmDesktop ? '19.5rem' : '20rem'}
        >
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <TextCaption color={blue}>High blood sugar risk</TextCaption>
            <H3 color={blue}>{sugarRisk}</H3>
          </FlexWrapper>
          <GraphImage level={sugarRisk.toLowerCase()} />
          <Small color={blue50}>
            Having increased sugar levels in your blood for long periods causes
            serious health problems if not taken care of. High blood sugar
            levels damage the blood vessels that supply blood to vital organs.
            This increases the risk of heart disease, stroke, kidney disease,
            and nerve problems.
          </Small>
        </WidgetWithGraph>
        <WidgetWithGraph
          maxWidth="38.125rem"
          height={isXsmDesktop ? '19.75rem' : '20rem'}
        >
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <TextCaption color={blue}>High blood presure risk</TextCaption>
            <H3 color={blue}>{pressureRisk}</H3>
          </FlexWrapper>
          <GraphImage level={pressureRisk.toLowerCase()} />
          <Small color={blue50}>
            High blood pressure damages your arteries by making them less
            elastic. This decreases the flow of blood and oxygen to your heart
            and can lead to heart disease. Decreased flow of blood to the heart
            can also cause chest pain, called angina.
          </Small>
        </WidgetWithGraph>
      </GridWrapper>
      <GridWrapper
        columns={isXsmDesktop ? 1 : 3}
        gap={isXsmDesktop ? '0.5rem' : '1rem'}
        justifyItems={isXsmDesktop ? 'center' : null}
        padding={isXsmDesktop ? '0 0 2.5rem' : '0 0 5rem'}
      >
        <Widget
          maxWidth="25.125rem"
          height={isXsmDesktop ? '10.75rem' : '14.375rem'}
        >
          <CircleWithBorder
            size={isXsmDesktop ? 5.625 : 7}
            color={stressedOrAnxious ? primary : white}
            showBorder={!stressedOrAnxious}
          >
            <Stress
              color={stressedOrAnxious ? white : blue50}
              style={isXsmDesktop ? { width: '2rem' } : null}
            />
            <ExclamationMarkWrapper
              size={isXsmDesktop ? 1.375 : 2}
              color={white}
              borderColor={stressedOrAnxious ? primary : blue50}
              onMouseEnter={() => setStressInfoVisible(true)}
              onMouseLeave={() => setStressInfoVisible(false)}
              onClick={() =>
                setStressInfoVisible(stressInfoVisible => !stressInfoVisible)
              }
              position="absolute"
            >
              <Caption color={stressedOrAnxious ? primary : blue50}>!</Caption>
            </ExclamationMarkWrapper>
          </CircleWithBorder>
          <Caption
            color={stressedOrAnxious ? primary : blue50}
            margin="0.875rem auto 0"
          >
            Stress
          </Caption>
          {stressInfoVisible && (
            <InfoTextWrapper>
              <Small>
                Studies suggest that the high levels of cortisol (the stress
                hormone) from long-term stress can increase blood cholesterol,
                triglycerides, blood sugar, and blood pressure. These are common
                risk factors for heart disease.
              </Small>
            </InfoTextWrapper>
          )}
        </Widget>
        <Widget
          maxWidth="25.125rem"
          height={isXsmDesktop ? '10.75rem' : '14.375rem'}
        >
          <CircleWithBorder
            size={isXsmDesktop ? 5.625 : 7}
            color={consumesAlcohol ? primary : white}
            showBorder={!consumesAlcohol}
          >
            <AlcoholGlass
              color={consumesAlcohol ? white : blue50}
              style={isXsmDesktop ? { width: '1.625rem' } : null}
            />
            <ExclamationMarkWrapper
              size={isXsmDesktop ? 1.375 : 2}
              color={white}
              borderColor={consumesAlcohol ? primary : blue50}
              onMouseEnter={() => setAlcoholInfoVisible(true)}
              onMouseLeave={() => setAlcoholInfoVisible(false)}
              onClick={() =>
                setAlcoholInfoVisible(alcoholInfoVisible => !alcoholInfoVisible)
              }
              position="absolute"
            >
              <Caption color={consumesAlcohol ? primary : blue50}>!</Caption>
            </ExclamationMarkWrapper>
          </CircleWithBorder>
          <Caption
            color={consumesAlcohol ? primary : blue50}
            margin="0.875rem auto 0"
          >
            Alcohol
          </Caption>
          {alcoholInfoVisible ? (
            <InfoTextWrapper>
              <Small>
                Alcohol consumption increases your blood pressure. Drinking
                excessive amounts of alcohol causes raised blood pressure which
                is one of the most important risk factors for having a heart
                attack or a stroke.
              </Small>
            </InfoTextWrapper>
          ) : null}
        </Widget>
        <Widget
          maxWidth="25.125rem"
          height={isXsmDesktop ? '10.75rem' : '14.375rem'}
        >
          <CircleWithBorder
            size={isXsmDesktop ? 5.625 : 7}
            color={usesTobaccoProducts ? primary : white}
            showBorder={!usesTobaccoProducts}
          >
            <Cigarette
              color={usesTobaccoProducts ? white : blue50}
              style={isXsmDesktop ? { width: '3.25rem' } : null}
            />
            <ExclamationMarkWrapper
              size={isXsmDesktop ? 1.375 : 2}
              color={white}
              borderColor={usesTobaccoProducts ? primary : blue50}
              onMouseEnter={() => setTobaccoInfoVisible(true)}
              onMouseLeave={() => setTobaccoInfoVisible(false)}
              onClick={() =>
                setTobaccoInfoVisible(tobaccoInfoVisible => !tobaccoInfoVisible)
              }
              position="absolute"
            >
              <Caption color={usesTobaccoProducts ? primary : blue50}>
                !
              </Caption>
            </ExclamationMarkWrapper>
          </CircleWithBorder>
          <Caption
            color={usesTobaccoProducts ? primary : blue50}
            margin="0.875rem auto 0"
          >
            Tobacco
          </Caption>
          {tobaccoInfoVisible ? (
            <InfoTextWrapper>
              <Small>
                Chemicals in cigarette smoke cause the blood to thicken and form
                clots inside veins and arteries. As a result blood pressure is
                increased and risk of stroke is higher.
              </Small>
            </InfoTextWrapper>
          ) : null}
        </Widget>
      </GridWrapper>
      <PrimaryButton
        maxWidth={isXsmDesktop ? '100%' : '11.125rem'}
        margin="0 auto"
        onClick={onCtaClick}
      >
        GET MY PLAN
      </PrimaryButton>
    </Container>
  );
};

const WidgetWithGraph = styled(Widget)`
  justify-content: space-between;
  padding: 3rem 3.125rem 2.75rem;

  @media ${xsmDesktop} {
    padding: 1.5rem;
  }
`;
const CircleWithBorder = styled(Circle)<{ showBorder?: boolean }>`
  ${({ showBorder }) =>
    showBorder
      ? `
    border: 0.0625rem solid ${blue50};
  `
      : ''}
`;
const ExclamationMarkWrapper = styled(Circle)<{ borderColor: string }>`
  position: absolute;
  top: 0;
  right: 0;
  border: 0.0625rem solid ${props => props.borderColor};
  cursor: pointer;
`;
const iconStyles = ({ color }: { color: string }) => `
  rect, line, path {
    stroke: ${color};
  }
`;
const AlcoholGlass = styled(AlcoholGlassIcon)<{ color: string }>`
  ${props => iconStyles(props)}
`;
const Stress = styled(StressIcon)<{ color: string }>`
  ${props => iconStyles(props)}
`;
const Cigarette = styled(CigaretteIcon)<{ color: string }>`
  ${props => iconStyles(props)}
`;
const TextCaption = styled(Caption)`
  @media ${mobile} {
    flex-basis: 65%;
  }
`;
const InfoTextWrapper = styled.div`
  position: absolute;
  top: 0;
  max-width: 20.625rem;
  width: 100%;
  padding: 1.25rem;
  border: 0.0625rem solid ${blue};
  border-radius: 0.625rem;
  background: ${white};
  transform: translateY(-100%);

  &:before {
    border-right: solid 0.625rem transparent;
    border-left: solid 0.625rem transparent;
    border-top: solid 0.625rem ${blue};
    transform: translateX(-50%);
    position: absolute;
    content: '';
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
  }

  &:after {
    border-right: solid 0.625rem transparent;
    border-left: solid 0.625rem transparent;
    border-top: solid 0.625rem ${white};
    transform: translateX(-50%);
    position: absolute;
    content: '';
    top: calc(100% - 0.0625rem);
    left: 50%;
    height: 0;
    width: 0;
  }
`;
