import React from 'react';
import styled from 'styled-components';

import { FlexWrapper } from 'components';
import { blue10, white } from 'styles/colors';

interface Props {
  maxWidth?: string;
  height?: string;
  className?: string;
}

export const Widget: React.FC<Props> = ({
  children,
  maxWidth,
  height,
  className,
}) => (
  <Container
    maxWidth={maxWidth}
    height={height}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    className={className}
  >
    {children}
  </Container>
);

const Container = styled(FlexWrapper)<{ maxWidth?: string; height?: string }>`
  position: relative;
  max-width: ${props => props.maxWidth || '25.125rem'};
  height: ${props => props.height || ''};
  border-radius: 0.625rem;
  border: 0.0625rem solid ${blue10};
  background: ${white};
`;
