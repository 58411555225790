import { Caption, Container, FlexWrapper, Svg } from 'components';
import React from 'react';
import styled from 'styled-components';

import { mobile, useQuery } from 'styles/breakpoints';
import { black70, black80, blue, primary } from 'styles/colors';

interface Props {
  yourResult: number | string;
  withPulsio: number;
}

const CURRENT_CONDITION_COLOR = '#F2614F';
const WITH_PULSIO_COLOR = '#16A65F';

const progressBarBlock = [
  { value: 1, barPosition: 0 },
  { value: 2, barPosition: 1 },
  { value: 3, barPosition: 2 },
  { value: 4, barPosition: 3 },
  { value: 5, barPosition: 4 },
  { value: 6, barPosition: 5 },
  { value: 7, barPosition: 6 },
  { value: 8, barPosition: 7 },
  { value: 9, barPosition: 8 },
  { value: 10, barPosition: 9 },
];

export const YourCondition: React.FC<Props> = React.memo(
  ({ yourResult, withPulsio }) => {
    const { isMobile } = useQuery();

    return (
      <StyledContainer>
        <ProgressBarWrapper>
          <FlexWrapper
            margin="0 0 1.5rem 0"
            justifyContent="space-between"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <BarTitle>Your heart health</BarTitle>
            <ConditionContainer>
              <StatusContainer
                margin={isMobile ? '0 1.4375rem 1.125rem 0' : '0 2rem 1.4rem 0'}
              >
                <StatusColor color={CURRENT_CONDITION_COLOR} />
                <StatusColorText>Current condition</StatusColorText>
              </StatusContainer>
              <StatusContainer
                margin={isMobile ? '0 0 1.125rem 0' : '0 2rem 1.4rem'}
              >
                <StatusColor color={WITH_PULSIO_COLOR} />
                <StatusColorText>With Pulsio</StatusColorText>
              </StatusContainer>
            </ConditionContainer>
          </FlexWrapper>

          <ProgressBar>
            {progressBarBlock.map((block, index) => (
              <ProgressBarBlock key={index}>
                {/* 0.4 is to keep text center */}
                <ProgressBarText
                  isCurrent
                  position={`${(index + (isMobile ? 0.1 : 0.4)) * 10}%`}
                >{`${(index + 1) * 10}%`}</ProgressBarText>
                <ProgressBarContent
                  isFilled={yourResult ? yourResult >= block.value : false}
                  color={CURRENT_CONDITION_COLOR}
                  notFilledColor="rgba(242, 97, 79, 0.2)"
                >
                  {yourResult === block.value && (
                    <Marker
                      position={`${(10 - yourResult) * 10}%`}
                      color={CURRENT_CONDITION_COLOR}
                    />
                  )}
                </ProgressBarContent>
              </ProgressBarBlock>
            ))}
          </ProgressBar>

          <ArrowContainer>
            <Arrow>
              <Svg
                src="icons/status_arrow_left"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              />
              <ArrowText
                margin={isMobile ? '0 0 0 0.5rem' : '0 0 0 0.5rem'}
                color={primary}
              >
                WEAK
              </ArrowText>
            </Arrow>
            <Arrow>
              <ArrowText
                margin={isMobile ? '0 0.5rem 0 0' : '0 0.5rem 0 0'}
                color={WITH_PULSIO_COLOR}
              >
                STRONG
              </ArrowText>
              <Svg
                src="icons/status_arrow_right"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              />
            </Arrow>
          </ArrowContainer>

          <ProgressBar>
            {progressBarBlock.map((block, index) => (
              <ProgressBarBlock key={index}>
                <ProgressBarContent
                  isFilled={withPulsio ? withPulsio >= block.value : false}
                  color={WITH_PULSIO_COLOR}
                  notFilledColor="rgba(22, 166, 95, 0.2)"
                >
                  {withPulsio === block.value && (
                    <Marker
                      position={`${(10 - withPulsio) * 10}%`}
                      color={WITH_PULSIO_COLOR}
                    />
                  )}
                </ProgressBarContent>
                <ProgressBarText
                  position={`${(index + (isMobile ? 0.1 : 0.4)) * 10}%`}
                >{`${(index + 1) * 10}%`}</ProgressBarText>
              </ProgressBarBlock>
            ))}
          </ProgressBar>
        </ProgressBarWrapper>
      </StyledContainer>
    );
  },
);

YourCondition.displayName = 'YourCondition';

const StyledContainer = styled(Container)`
  background: #fff;
  border-radius: 6px;
  padding: 3rem;
  position: relative;
  margin: 2.5rem 0 1.875rem 0;

  @media ${mobile} {
    padding: 2rem;
    margin: -1rem 0 1.875rem 0;
  }
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;

  @media ${mobile} {
    padding: 0.5rem 0;
  }
`;

const ProgressBar = styled.div`
  display: grid;
  grid-template-columns: repeat(${progressBarBlock.length}, 1fr);
  border-radius: 2rem;
  grid-gap: 2px;
  position: relative;
`;

const ProgressBarBlock = styled.div`
  flex: 1;
  height: 0.5rem;
  overflow: hidden;
  border: none;
  &:first-of-type {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  &:last-of-type {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
`;

const ProgressBarContent = styled.div<{
  isFilled: boolean;
  color: string;
  notFilledColor: string;
}>`
  height: 100%;
  background-color: ${props =>
    props.isFilled ? props.color : props.notFilledColor};
  color: ${props => (props.isFilled ? black80 : black70)};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.05rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mobile} {
    font-size: 0.5rem;
    line-height: 0.6rem;
  }
`;

const BarTitle = styled(Caption)`
  color: ${blue};

  @media ${mobile} {
    margin-bottom: 1rem;
  }
`;

const StatusContainer = styled.div<{
  margin?: string;
}>`
  margin: ${props => props.margin || '0 0.5rem 1.4rem'};
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

const StatusColor = styled.div<{ color: string }>`
  border-radius: 50%;
  background: ${props => props.color};
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;

  @media ${mobile} {
    margin-right: 0.65rem;
    width: 26px;
    height: 26px;
  }
`;

const ConditionContainer = styled.div`
  display: flex;
`;

const StatusColorText = styled(Caption)`
  color: ${blue};
  text-align: center;
  @media ${mobile} {
    font-size: 0.875rem;
    line-height: 1rem;
    max-width: 5.7rem;
    word-break: break-word;
    text-align: left;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0 0.5rem 0;
  @media ${mobile} {
    margin: 0.8rem 0 0.8rem 0;
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowText = styled.span<{ margin: string; color: string }>`
  color: ${props => props.color};
  line-height: 0.8125rem;
  font-size: 0.6875rem;
  margin: ${props => props.margin};
  font-family: acumin-pro, sans-serif;
`;
const ProgressBarText = styled.div<{ position: string; isCurrent?: boolean }>`
  font-size: 0.6875rem;
  line-height: 0.825rem;
  text-align: center;
  left: ${props => props.position};
  position: absolute;
  bottom: ${props => (props.isCurrent ? '200%' : '-250%')};
  @media ${mobile} {
    font-size: 0.5rem;
    line-height: 0.625rem;
  }
`;
const Marker = styled.div<{ position: string; color: string }>`
  height: 250%;
  width: 3px;
  background: ${props => props.color};
  position: absolute;
  right: ${props => props.position};
  top: -60%;
`;
