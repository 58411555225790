import React from 'react';
import styled from 'styled-components';

import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { almostWhite, almostWhite50, blue, white } from 'styles/colors';
import {
  Caption,
  FlexWrapper,
  H1,
  Image,
  OutlineButton,
  Regular,
} from 'components';
import { ComponentWithCtaEvent } from 'utils/interfaces';

export const OurPromise: React.FC<ComponentWithCtaEvent> = ({ onCtaClick }) => {
  const { isTablet } = useQuery();

  return (
    <Background>
      <H1
        color={white}
        textAlign="center"
        margin={isTablet ? '0 auto 1.5rem' : '0 auto 4rem'}
      >
        Our Promise
      </H1>
      <Container>
        <FlexWrapper
          alignItems="center"
          justifyContent="space-between"
          flexDirection={isTablet ? 'column' : 'row'}
        >
          <DoctorImage noLazyLoad preload src="doctor_portrait" />
          <LeftColumn>
            <Regular
              color={almostWhite}
              padding={isTablet ? '1.875rem 0 0' : null}
            >
              Our mission is to help you keep your cardiovascular system in top
              shape in a safe, personalized, and balanced way. Heart and blood
              sugar-related conditions are our country’s top cause of death, but
              most are preventable if taken the right measures. It’s only up to
              us to prevent cardiovascular conditions before they wreak havoc in
              our lives.
            </Regular>
            <Regular color={almostWhite} padding="1rem 0">
              Speaking for the entire team, we promise to be honest with you.
              That means we’ll show you the research and work to tailor our
              guidance to you as an individual.
            </Regular>
            <Regular color={almostWhite} padding="0 0 1rem">
              Our goal is to make it easier and better for you – today and
              always.
            </Regular>
            <Caption color={white}>George C., MD, MS</Caption>
            <Regular color={almostWhite50} padding="0 0 2rem">
              Pulsio Medical Lead
            </Regular>
            <OutlineButton
              maxWidth={isTablet ? '100%' : '11.125rem'}
              className="inverted"
              onClick={onCtaClick}
            >
              GET MY PLAN
            </OutlineButton>
          </LeftColumn>
        </FlexWrapper>
      </Container>
    </Background>
  );
};

OurPromise.displayName = 'OurPromise';

const Background = styled.div`
  padding: 6.25rem 0 11.25rem;
  background-color: ${blue};

  @media ${tablet} {
    padding: 3.875rem 1.5rem 5rem;
  }
`;
const Container = styled.div`
  max-width: 63.125rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 63.125rem) {
    padding: 0 4%;
  }
`;
const DoctorImage = styled(Image)`
  max-width: 26rem;
  width: 100%;

  @media ${mobile} {
    max-width: 20.5rem;
  }
`;
const LeftColumn = styled.div`
  max-width: 30.625rem;
  width: 100%;
`;
